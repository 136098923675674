/* You can add global styles to this file, and also import other style files */
html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

html, body {
  padding: 0;
  margin: 0;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
